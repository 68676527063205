@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.panel-title {
    .panel-title-right {
        float: right;
        margin-right: 150px;
    }
}

table {
    border: solid 1px #333333;
    margin-bottom: 60px;

    th {
        background-color: #DDDDDD;
        font-weight: bold;
    }

    td, th {
        width: 40px;
        height: 40px;
        text-align: center;
        border: solid 1px #BBBBBB;
    }

    thead {
        th {
            border-bottom: solid 1px #333333;
        }
    }

    tbody {
        th {
            border-right: solid 1px #333333;
        }
    }
}

@media print {
    table th {
        background-color: #DDDDDD !important;
        -webkit-print-color-adjust: exact;
    }
}
